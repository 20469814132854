import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  useToast,
  Spinner,
  Select,
  HStack,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Text,
} from '@chakra-ui/react';
import { MdClose, MdPlayArrow, MdPause } from 'react-icons/md';

interface AudioPlayerProps {
  text: string;
  onClose: () => void;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ text, onClose }): JSX.Element => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioUrl, setAudioUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const toast = useToast();

  const backendRootUrl = 'https://backend-worker.pezhman32.workers.dev';

  const handleClose = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.src = '';
    }
    onClose();
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play();
        setIsPlaying(true);
      } else {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleSpeedChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSpeed = parseFloat(event.target.value);
    setPlaybackSpeed(newSpeed);
    if (audioRef.current) {
      audioRef.current.playbackRate = newSpeed;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${backendRootUrl}/api/synthesize`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ text }),
        });
        if (!response.ok) throw new Error('Failed to generate audio');

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);
      } catch (error: any) {
        toast({
          title: 'Error',
          description: error.message || 'Failed to generate audio',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [text, toast]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackSpeed;
    }
  }, [playbackSpeed]);

  useEffect(() => {
    const savedSpeed = localStorage.getItem('playbackSpeed');
    if (savedSpeed) {
      const speed = parseFloat(savedSpeed);
      setPlaybackSpeed(speed);
      if (audioRef.current) {
        audioRef.current.playbackRate = speed;
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('playbackSpeed', playbackSpeed.toString());
  }, [playbackSpeed]);

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleSliderChange = (newTime: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const formattedTime = `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    return formattedTime;
  };

  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      bg="rgba(255, 255, 255, 0.8)"
      boxShadow="0 -2px 10px rgba(0,0,0,0.1)"
      p={4}
      backdropFilter="blur(10px)"
      zIndex={100}
    >
      {isLoading ? (
        <Spinner size="lg" color="teal.500" />
      ) : (
        <>
          <audio
            ref={audioRef}
            src={audioUrl}
            onEnded={handleClose}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onTimeUpdate={handleTimeUpdate}
            onLoadedMetadata={handleLoadedMetadata}
          />
          <HStack spacing={4} align="center">
            <IconButton
              icon={isPlaying ? <MdPause /> : <MdPlayArrow />}
              aria-label={isPlaying ? 'Pause' : 'Play'}
              onClick={handlePlayPause}
            />
            <Text minWidth="40px" textAlign="center">
              {formatTime(currentTime)}
            </Text>
            <Slider
              aria-label="audio-slider"
              min={0}
              max={duration}
              value={currentTime}
              onChange={handleSliderChange}
              flex="1"
            >
              <SliderTrack>
                <SliderFilledTrack bg="teal.500" />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <Text minWidth="40px" textAlign="center">
              {formatTime(duration)}
            </Text>
            <Select
              value={playbackSpeed}
              onChange={handleSpeedChange}
              width="auto"
            >
              <option value={0.5}>0.5x</option>
              <option value={0.75}>0.75x</option>
              <option value={1}>1x</option>
              <option value={1.25}>1.25x</option>
              <option value={1.5}>1.5x</option>
              <option value={2}>2x</option>
            </Select>
            <IconButton
              position="absolute"
              right={4}
              top="50%"
              transform="translateY(-50%)"
              size="sm"
              aria-label="Close audio player"
              icon={<MdClose />}
              onClick={handleClose}
            />
          </HStack>
        </>
      )}
    </Box>
  );
};

export default AudioPlayer;
