import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  FormControl,
  Select,
  Input,
  Heading,
  theme,
  CircularProgress,
  IconButton, Tooltip
} from '@chakra-ui/react';
import { Article, HistoryItem, StoryRequest, WebPage } from '../types/HistoryItem';
import { useNavigate } from 'react-router-dom';
import "../styles/createStory.css";
import "../styles/general.css";
import {caseTypesArray, difficultyLevels, RoutesMap, tenseTypesArray} from "../types/constants";
import {StoryType} from "../types/CreateStory";
import {BiHome} from "react-icons/all";
import nosleep from 'nosleep.js';

export const CreateStoryPage = () => {
  const [history, setHistory] = useState<HistoryItem[]>([]);
  const [type, setType] = useState<StoryType>('story');
  const [storyContext, setStoryContext] = useState('');
  const [difficultyLevel, setDifficultyLevel] = useState(difficultyLevels[2]);
  const [numberOfWords, setNumberOfWords] = useState('150');
  const [caseType, setCaseType] = useState('');
  const [tenseType, setTenseType] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState<Article[]>([]);
  const [newsQuery, setNewsQuery] = useState('');
  const [newsContext, setNewsContext] = useState<Article>();
  const [webContext, setWebContext] = useState<WebPage>();

  const noSleep = new nosleep();

  useEffect(() => {
    const localStorageHistory = window.localStorage.getItem('stories');
    setHistory(localStorageHistory ? JSON.parse(localStorageHistory) : []);
  }, []);

  useEffect(() => {
    if (type === 'news') {
      fetch('https://backend-worker.pezhman32.workers.dev/api/get-news-headlines?q=' + encodeURIComponent(newsQuery))
      .then(response => response.json())
      .then(data => data.articles as Article[])
      .then((articles: Article[]) => {
        setArticles(articles);
      })
      .catch((error) => { alert('Error getting news: ' + error); });
    }
  }, [newsQuery, type]);

  const handleGenerateStory = async () => {
    if (loading) {
      return;
    }

    noSleep.enable();

    setLoading(true);
    console.log('>>> History', history);

    const requestData: StoryRequest = {
      difficultyLevel: difficultyLevel,
      caseType: caseType,
      tense: tenseType,
      from: 'de',
      to: 'en',
      type: type,
      numberOfWords: +numberOfWords,
      storyContext: storyContext,
      newsContext: newsContext,
      webContext: type === 'web' && webContext?.url ? webContext : undefined
    };

    const backendRootUrl = 'https://backend-worker.pezhman32.workers.dev';
    const storyResponse: HistoryItem | null = await fetch(`${backendRootUrl}/api/generateContent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      })
      .then(response => response.json())
      .then(data => data as HistoryItem)
      .catch((error) => { alert('Error: ' + error); return null; })
      .finally(() => {
        setLoading(false);
        noSleep.disable();
      });
    
    console.log('>>> Story', storyResponse);

    if (storyResponse === null) {
      return;
    }

    const story: HistoryItem = {
      ...requestData,
      ...storyResponse
    }

    history.push(story);
    setHistory(history);
    
    window.localStorage.setItem('stories', JSON.stringify(history));
    navigate('/stories/' + story.id);
  };

  console.log('>>> Articles', articles);

  return (
    <ChakraProvider theme={theme}>
        <div className="flex flex-col w-full h-screen">
          <Box bg="red.300" p={4}>
            <Heading size="lg">Generate New Story</Heading>
          </Box>

          <Box bg="white" p={4}>
            <h3 className="page-title">Create Story</h3>

            <FormControl className="field">
              <label>Type:</label>
              <Select value={type} onChange={(event) => setType(event.target.value as StoryType)}
                className="input-field "
              >
                <option value="story">Story</option>
                <option value="news">News</option>
                <option value="web">Web</option>
              </Select>
            </FormControl>

            {type == 'story' && 
              <FormControl className="field">
                <label>Context (optional):</label>
                <Input placeholder='e.g. Shopping perfume' onChange={(event) => setStoryContext(event.target.value)}
                  className="input-field " />
              </FormControl>
            }

            {type == 'news' && articles && 
              <FormControl className="field">
              <label>Search (optional):</label>
              <Input placeholder='e.g. Hamburg Demo' onChange={(event) => setNewsQuery(event.target.value)}
                className="input-field" value={newsQuery} />
            </FormControl>
            }

            {type == 'news' && articles && 
              <FormControl className="field">
                <label>News Title:</label>
                <Select onChange={(event) => setNewsContext(articles.find(a => a.url == event.target.value))} className="input-field">
                  <option value="">Select News Title</option>
                  {
                    articles.map((article) => (<option key={article.url} value={article.url}>{article.title}</option>))
                  }
                </Select>
              </FormControl>
            }

            {type == 'web' && 
              <FormControl className="field">
                <label>Web Page URL:</label>
                <Input placeholder='e.g. https://example.com' onChange={(event) => setWebContext({ url: event.target.value, content: '' })}
                  className="input-field " />
              </FormControl>
            }

            <FormControl className="field">
              <label>Difficulty Level:</label>
              <Select value={difficultyLevel} onChange={(event) => setDifficultyLevel(event.target.value)}
                      className="input-field"
              >
                {
                  difficultyLevels.map((level) => (<option key={level} value={level}>{level}</option>))
                }
              </Select>
            </FormControl>

            <FormControl className="field">
              <label>Number of Words:</label>
              <Input type="number" value={numberOfWords} onChange={(event) => setNumberOfWords(event.target.value)}
                     className="input-field"
              />
            </FormControl>

            <FormControl className="field">
              <label>Case Type:</label>
              <Select value={caseType} onChange={(event) => setCaseType(event.target.value)}
                      className="input-field"
              >
                <option value="">Select Case (optional)</option>
                {
                  caseTypesArray.map((type) => (<option key={type} value={type}>{type}</option>))
                }
              </Select>
            </FormControl>

            <FormControl className="field">
              <label>Time Type:</label>
              <Select value={tenseType} onChange={(event) => setTenseType(event.target.value)}
                      className="input-field"
              >
                <option value="">Select Tense (optional)</option>
                {
                  tenseTypesArray.map((type) => (<option key={type} value={type}>{type}</option>))
                }
              </Select>
            </FormControl>

            <div className="action-button">
              <Button
                  onClick={handleGenerateStory}
                  isLoading={loading}
                  loadingText="Generating story with AI..."
                  colorScheme='teal'
                  spinner={<CircularProgress size={4} isIndeterminate color='green.300' />}>
                Generate Story
              </Button>
              <Tooltip label="Home">
                <IconButton aria-label="Home"
                            onClick={() => window.location.href = RoutesMap.HISTORY}
                            icon={<BiHome />}
                            ml={2}
                />
              </Tooltip>
            </div>

          </Box>
        </div>
    </ChakraProvider>
  );
};
