import React, { useState, useEffect } from 'react';
import {
  Text,
  Box,
  Button,
  Heading,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Divider,
  IconButton,
  Tooltip,
  Image,
  Icon
} from '@chakra-ui/react';
import { HistoryItem } from '../types/HistoryItem';
import { useParams } from 'react-router-dom';
import AudioPlayer from '../components/AudioPlayer';
import { StoryTags } from '../components/StoryTags';
import { InfoIcon } from '@chakra-ui/icons';
import { BiCog, BiHome, BiLink, BiText, BiVolume, BiVolumeFull } from "react-icons/all";
import { MdVolumeUp } from 'react-icons/md';
import { RoutesMap } from "../types/constants";


export const ReadingPage = () => {
  const [history, setHistory] = useState<HistoryItem[]>([]);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const { storyId } = useParams();

  // find story from history with id found in the url
  const story = history.find(item => item.id === storyId);

  useEffect(() => {
    const localStorageHistory = window.localStorage.getItem('stories');
    setHistory(localStorageHistory ? JSON.parse(localStorageHistory) : []);
  }, []);

  if (!story) {
    return (
      <div className="flex flex-col w-full h-screen">
        <Box bg="red.300" p={4}>
          <Heading size="lg">Story not found</Heading>
        </Box>
      </div>
    );
  }

  const increaseReadingCount = () => {
    story.readsCount = (story.readsCount ? story.readsCount : 0) + 1;
    
    window.localStorage.setItem('stories', JSON.stringify(history));
    setHistory(history);
  }

  const getStoryText = () => {
    const element = document.getElementById('story-de-text');
    if (!element) return '';
    return element.textContent || '';
  };

  return (
    <div className="flex flex-col w-full h-screen">
        <Box bg="red.300" p={4}>
          <Heading size="lg">
            {story.title.substring(0, 17)}...
          </Heading>
        </Box>

        <Box bg="white" p={4}>
          <StoryTags story={story} />

          <div id="story-content">
            <div id="story-de-text">
              <Text fontSize='3xl' lang='de'>
                {story?.title}
              </Text>
              
              {story.text.split('\n').map((item, index) => (
                <Text fontSize='xl' lang='de' key={`story-line-${index}`}>
                  {story.type === 'news' && index === 0 && story.newsContext?.urlToImage && 
                    <Image
                    boxSize='50%'
                    objectFit='cover'
                    float='right'
                    ml={3}
                    mb={3}
                    src={story.newsContext!.urlToImage}
                  />}
                  {item}
                </Text>
              ))}
            </div>
            <br />

            {showAudioPlayer && (
              <AudioPlayer 
                text={getStoryText()} 
                onClose={() => setShowAudioPlayer(false)} 
              />
            )}
            
            {/* <Button
              colorScheme='teal'
              onClick={() => {
                increaseReadingCount();
                setShowAudioPlayer(true);
              }}
              variant='solid'
              mb={4}
            >
              Read & translate
              <Icon as={MdVolumeUp} ml={2} />
            </Button> */}

            <IconButton aria-label="Read"
                  colorScheme='teal'
                  
                  onClick={() => {
                    increaseReadingCount();
                    setShowAudioPlayer(true);
                  }}
                  icon={<BiVolumeFull />}
                  ml={2}
              />

            <Tooltip label="Home">
              <IconButton aria-label="Home"
                  onClick={() => window.location.href = RoutesMap.HISTORY}
                  icon={<BiHome />}
                  ml={2}
              />
            </Tooltip>

            <Tooltip label="Create new story">
              <IconButton aria-label="Home"
                          onClick={() => window.location.href = RoutesMap.CREATE_STORY}
                          icon={<BiCog />}
                          ml={2}
              />
            </Tooltip>

            {story?.type === 'news' &&
            <Tooltip label="Link to news">
              <IconButton aria-label="Link to news"
                          onClick={() => window.open(story.newsContext!.url, '_blank')}
                          icon={<BiLink />}
                          ml={2}
              />
            </Tooltip>
            }

            <Divider mt={5} mb={5} />

            <Text fontSize='3xl'>Vocabulary</Text>
            <TableContainer>
              <Table size='sm'>
                <Thead>
                  <Tr>
                    <Th>German</Th>
                    <Th>English</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {story?.vocabs.map((item, index) => (
                    <Tr key={index}>
                      <Td lang='de'>{item.key}</Td>
                      <Td lang='en'>{item.value}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>

            {/* <ul>
              {story?.vocabs.map((item, index) => (
                <li>
                  <div lang='de'>{item.key}</div>: <div lang='en'>{item.value}</div>
                </li>
              ))}
            </ul> */}
            
            <Text mt={5} fontSize='3xl'>Grammar</Text>
            {story?.grammar.map((item, index) => (
              <Text fontSize='l' mt={2} key={`grammar-${index}`}>
                <InfoIcon /> <b>{item.key}</b>: {item.value}
              </Text>
            ))}
          </div>

        </Box>
    </div>
  );
};
